import store from '@/store'
import { getStorageAuthData } from '@/utils/localStorage'

const getChatId = ():string => {
  const storageData = getStorageAuthData()
  return storageData['mabell_chat_id'] || '';
}

const initChat = async () => {
  const chatId = getChatId()

  if(chatId) {
    store.commit('chat/setChatId', chatId)
    await store.dispatch('chat/getMessages', { id: chatId })
  }
}

export {
  initChat,
  getChatId
}
