import store from '@/store'
import { TokenType } from '@/types'
import { getStorageAuthData, removeStorageAuthData } from '@/utils/localStorage'


const getToken = () => {
  const storageData = getStorageAuthData()
  const tokenJson = storageData['mabell_auth_token']
  const token = tokenJson ? JSON.parse(tokenJson): {};
  console.log(token, 'token')
  return token
}

const getUserId = () => {
  const storageData = getStorageAuthData()
  return storageData['mabell_user_id']
}

const authenticate = async (token: TokenType, companyId:string) => {
  if (Object.prototype.hasOwnProperty.call(token, 'access_token')) {
    try {
      await store.dispatch('user/getUser', {companyId})
      store.commit('user/setToken', token.access_token)
      store.commit('chat/setFinished', store.getters['user/getUser'].chat_review_status !== "chat_active")
    } catch (e) {
      logout()
    }
  }
}

const logout = () => {
  removeStorageAuthData()
  removeStorageAuthData()
  store.commit('user/setUser', null)
}

export {
  getToken,
  getUserId,
  authenticate,
  logout
}
